import type { Dispatch } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';

import ListingCardV3 from 'app/shared/modules/listing-cards/ListingCardV3';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import 'app/shared/modules/listing-cards/ListingWrapper.scss';

// Tracking & constants
import ImpressionTracking from 'app/shared/modules/ImpressionTracking';
import { ListingProvider } from './ListingCardProvider';
import type {
  SaveHomeTriggerLocation,
  SaveHomeTriggerObject,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import type { ListingType } from 'app/types';
import type { SimilarListingDetails } from 'app/types/similarListingDetails.type';

/*
TODO HPWEB-6194:
1. convert to functional component
2. Add commute time tag to bigger listing card.
*/

interface ListingWrapperProps {
  className?: string;
  commuteTime?: any;
  customRef?: any;
  dispatch: Dispatch<any>;
  keepMapLocation?: boolean;
  key: string;
  listing: ListingType | SimilarListingDetails;
  onClick: () => void;
  setActiveMarkerOnHover: boolean;
  shouldLazyLoadListingCardPhotos: boolean;
  // eslint-disable-next-line no-unused-vars
  trackImpression: (listing: ListingType | SimilarListingDetails) => void;
  triggerLocation: SaveHomeTriggerLocation;
  triggerObject: SaveHomeTriggerObject;
  deferRender?: boolean;
}

interface ListingWrapperState {
  listingViewed: boolean;
}

class ListingWrapper extends React.Component<ListingWrapperProps, ListingWrapperState> {
  static displayName = 'ListingWrapper';
  static propTypes = {
    keepMapLocation: PropTypes.bool,
    shouldLazyLoadListingCardPhotos: PropTypes.bool,
    onClick: PropTypes.func,
    listing: PropTypes.object.isRequired,
    setActiveMarkerOnHover: PropTypes.bool,
    trackImpression: PropTypes.func,
    customRef: PropTypes.object,
  };

  static defaultProps = {
    keepMapLocation: false,
    shouldLazyLoadListingCardPhotos: false,
    onClick: () => {},
    trackImpression: () => {},
    setActiveMarkerOnHover: true,
    customRef: null,
    deferRender: false,
  };

  constructor(props: ListingWrapperProps) {
    super(props);
    const { listing } = this.props;

    this.state = {
      listingViewed: includes(listing?.userItemTypes, 'viewed') || includes(listing?.userItemTypes, 'favorite'),
    };
  }

  shouldComponentUpdate(nextProps: ListingWrapperProps) {
    const { commuteTime, listing } = this.props;
    const thisPriceDisplay = listing.listingMinMaxPriceBeds.priceDisplay;
    const nextPriceDisplay = nextProps.listing.listingMinMaxPriceBeds.priceDisplay;

    if (listing.userItemTypes !== nextProps.listing?.userItemTypes) {
      return true;
    } else if (thisPriceDisplay !== nextPriceDisplay) {
      return true;
    } else if (nextProps.commuteTime !== commuteTime) {
      return true;
    } else {
      return false;
    }
  }

  handleMouseEnter = () => {
    const { dispatch, listing, setActiveMarkerOnHover } = this.props;
    if (setActiveMarkerOnHover) {
      dispatch(ListingEngineActions.setActiveMarkerMaloneLotId(listing.maloneLotIdEncoded));
    }
  };

  handleMouseExit = () => {
    const { dispatch, setActiveMarkerOnHover } = this.props;
    if (setActiveMarkerOnHover) {
      dispatch(ListingEngineActions.setActiveMarkerMaloneLotId(null));
    }
  };

  render() {
    const {
      className,
      listing,
      shouldLazyLoadListingCardPhotos,
      customRef,
      key,
      keepMapLocation,
      onClick,
      triggerLocation,
      triggerObject = 'property_card',
      deferRender,
    } = this.props;

    const { listingViewed } = this.state;

    if (!listing) {
      return null;
    }

    return (
      <ListingProvider listing={listing} triggerLocation={triggerLocation} triggerObject={triggerObject}>
        <li
          className={cx('ListingWrapper-container', className)}
          key={key}
          ref={customRef}
          id={listing.maloneLotIdEncoded}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseExit}
        >
          <ListingCardV3
            keepMapLocation={keepMapLocation}
            onClick={onClick}
            listingViewed={listingViewed}
            shouldLazyLoadListingCardPhotos={shouldLazyLoadListingCardPhotos}
            shouldDeferRender={deferRender}
          />
          <ImpressionTracking analyticsCallback={() => this.props.trackImpression(listing)} />
        </li>
      </ListingProvider>
    );
  }
}

export default connect()(ListingWrapper);
